export enum Form1099NECPreparationStatus {
    DRAFT_PREPARED = 'DRAFT_PREPARED',
    DRAFT_SENT = 'DRAFT_SENT',
    DRAFT_SEEN = 'DRAFT_SEEN',
    DRAFT_REJECTED = 'DRAFT_REJECTED',
    DRAFT_APPROVED = 'DRAFT_APPROVED',
    FILED = 'FILED',
    COMPLETED = 'COMPLETED'
}

export enum PublicSaveAction {
    APPROVE = 'APPROVE',
    REJECT = 'REJECT'
}

export enum InternalSaveAction {
    SEND = 'SEND',
    REPREPARE = 'REPREPARE',
    SKIP_APPROVAL = 'SKIP_APPROVAL',
    SUBMIT = 'SUBMIT',
    COMPLETE = 'COMPLETE'
}