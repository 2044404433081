























import {Component, Prop, Vue} from 'vue-property-decorator';
import EmploymentSearch from "@/components/common/EmploymentSearch.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import AddForm1099NECPreparationPayload from "@/dto/preparation/AddForm1099NECPreparationPayload";
import {IContractor} from "@/components/payroll/business/employments/contractors/Contractor";
import PreparationService from "@/services/PreparationService";
import {namespace} from "vuex-class";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {CounterpartyType} from "@/constants/CounterpartyType";
import PersonService from "@/services/PersonService";
import CompanyService from "@/services/CompanyService";

const AppModule = namespace("App");

@Component({
  components: {PortalCheckbox, PortalInput, EmploymentSearch}
})
export default class AddForm1099NECPreparationModal extends Vue {

  @AppModule.Action private startLoading!: () => void;
  @AppModule.Action private stopLoading!: () => void;

  @Prop()
  payerId!: number;

  contractor: IContractor | null = null;

  payload = new AddForm1099NECPreparationPayload();

  hasAddress = false;

  @Prop()
  onCreated!: () => void

  create(): void {
    ifValid(this, () => {
      this.payload.contractorId = this.contractor?.id as number;
      this.payload.contractorType = this.contractor?.counterpartyType as CounterpartyType;
      this.startLoading();
      PreparationService.prepareNew1099NEC(this.payload).then (
          ok => {
            this.onCreated();
            this.stopLoading();
          },
          err => {
            processError(err, this);
            this.stopLoading();
          }
      )
    });
  }

  checkAddress(){
    if(this.contractor){
      switch (this.contractor.counterpartyType!){
        case CounterpartyType.COMPANY: this.checkCompanyAddress(); break;
        case CounterpartyType.PERSON: this.checkPersonAddress(); break;
      }
    }
  }

  checkPersonAddress(): Promise<void> {
    this.startLoading();
    return PersonService.getAddressById(this.contractor!.details.id!).then(
        ok => {
          this.hasAddress = !!ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

  checkCompanyAddress(): Promise<void> {
    this.startLoading();
    return CompanyService.getAddressById(this.contractor!.details.id!).then(
        ok => {
          this.hasAddress = !!ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

}
