import { render, staticRenderFns } from "./AddForm1099NECPreparationModal.vue?vue&type=template&id=f3b38432&scoped=true&"
import script from "./AddForm1099NECPreparationModal.vue?vue&type=script&lang=ts&"
export * from "./AddForm1099NECPreparationModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3b38432",
  null
  
)

export default component.exports